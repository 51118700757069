/**
 *
 * Dashboard
 *
 */

import React, { useEffect, useState } from 'react';

import { SummaryType } from '@ariksa/inventory-core/api';
import { AlertHistoryBy } from '@ariksa/notification/api';
import { VulnerabilitiesCharts } from '@ariksa/reporting/api';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { FilterComponent, FilterRouter } from 'components/FilterView';
import { ActiveResourceProvider } from 'containers/ActiveCloudResource/context/ActiveResourceProvider';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectApp, selectUser } from 'containers/App/selectors';
import { CloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/Loadable';
import { DataSecurityOverview } from 'containers/Dashboard/DataSecurityOverview/Loadable';
import { IdentityOverview } from 'containers/Dashboard/IdentityOverview/Loadable';
import { DashboardOverview } from 'containers/Dashboard/Overview';
import { dashboardSaga } from 'containers/Dashboard/saga';
import { actions, sliceKey, reducer } from 'containers/Dashboard/slice';
import { VulnerabilityOverview } from 'containers/Dashboard/VulnerabilityOverview/Loadable';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useInjector } from 'utils/inject';

export function Dashboard() {
  useInjector(sliceKey, reducer, dashboardSaga);
  const { info } = useSelector(selectUser);
  const dispatch = useDispatch();
  const { cloudAccounts, environments, user } = useSelector(selectApp);
  const { environmentId, accountId } = useAccessBoundary();
  const { aiParamExtraction } = useSelector(selectSharedState);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    setEndDate(
      aiParamExtraction.data?.end_date
        ? dayjs(aiParamExtraction.data?.end_date).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
    );

    setStartDate(
      aiParamExtraction.data?.start_date
        ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
        : dayjs().subtract(29, 'day').format('YYYY-MM-DD'),
    );
  }, [aiParamExtraction.data]);

  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;
    const accountIds = !!accountId ? [accountId] : undefined;
    const openFindingHistoryPayload = {
      environmentId: environmentId as string,
      accountId,
      startDate,
      endDate,
    };
    const startDate_7 = aiParamExtraction.data?.start_date
      ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
      : dayjs().subtract(7, 'day').format('YYYY-MM-DD');

    dispatch(
      actions.getResourcesCount({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
        },
      }),
    );
    dispatch(
      actions.getAlertingResources({
        q: {
          environmentId: environmentId as string,
          accountIds,
        },
      }),
    );
    dispatch(
      actions.getCloudSummary({
        q: {
          environmentId: environmentId as string,
          accountId: !!accountId ? [accountId] : [],
          summaryType: SummaryType.Org,
        },
      }),
    );
    dispatch(
      actions.getInventorySummary({
        q: {
          environmentId: environmentId as string,
          accountId: !!accountId ? [accountId] : [],
          summaryType: SummaryType.IdentityAgnosticV2,
        },
      }),
    );
    dispatch(
      actions.getInventoryResourcesSummary({
        q: {
          environmentId: environmentId as string,
          accountId: !!accountId ? [accountId] : [],
        },
      }),
    );

    dispatch(
      actions.getOpenFindings({
        q: {
          environmentId: environmentId as string,
          accountId,
        },
      }),
    );
    dispatch(
      actions.getInfrastructurePostureSummary({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
          summaryType: SummaryType.Infrastructure,
        },
      }),
    );
    dispatch(
      actions.getEntitiesHistory({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
        },
      }),
    );
    dispatch(
      actions.getAlertsHistory({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate,
          endDate,
        },
      }),
    );
    dispatch(
      actions.getFindingsCategorySummary({
        q: {
          environmentId: environmentId as string,
          accountId,
        },
      }),
    );
    dispatch(
      actions.getDataCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Data',
        },
      }),
    );
    dispatch(
      actions.getIdentityCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Identity',
        },
      }),
    );

    dispatch(
      actions.getComputeCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Compute',
        },
      }),
    );

    dispatch(
      actions.getNetworkCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Network',
        },
      }),
    );

    dispatch(
      actions.getCredentialCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Credentials',
        },
        onSuccess: r => {
          console.log(r);
        },
      }),
    );

    dispatch(
      actions.getDomainsCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          resourceSubCategory: 'Domains',
        },
      }),
    );

    dispatch(
      actions.getOpenAlerts({
        q: {
          environmentId: environmentId as string,
          accountId,
        },
      }),
    );
    dispatch(
      actions.getOpenFindingHistory({
        q: {
          ...openFindingHistoryPayload,
          historyBy: AlertHistoryBy.Trend,
        },
      }),
    );
    dispatch(
      actions.getIdentityAuthorizationOpenFindingHistory({
        q: {
          ...openFindingHistoryPayload,
          historyBy: AlertHistoryBy.Trend,
          categoryClass: AlertCategoryClass.IdentityAuthorization,
        },
      }),
    );
    dispatch(
      actions.getDataSecurityOpenFindingHistory({
        q: {
          ...openFindingHistoryPayload,
          historyBy: AlertHistoryBy.Trend,
          categoryClass: AlertCategoryClass.DataSecurity,
        },
      }),
    );
    dispatch(
      actions.getCloudPostureOpenFindingHistory({
        q: {
          ...openFindingHistoryPayload,
          historyBy: AlertHistoryBy.Trend,
          categoryClass: AlertCategoryClass.CloudPosture,
        },
      }),
    );
    dispatch(
      actions.getSummaryOfAllDataSources({
        q: {
          environmentId: environmentId as string,
          accountId: !!accountId ? [accountId] : [],
        },
      }),
    );
    dispatch(
      actions.getAnalyzedDataSourcesCount({ q: { environmentId, accountIds } }),
    );
  }, [
    environments,
    dispatch,
    environmentId,
    accountId,
    startDate,
    endDate,
    user.info.org_uuid,
    aiParamExtraction.data,
    cloudAccounts.data,
  ]);

  /*vulnerabilities*/
  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;
    const accountIds = !!accountId ? [accountId] : undefined;
    dispatch(
      actions.getVulnerableResourcesSummary({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
          summaryType: SummaryType.VulnerableResource,
        },
      }),
    );
    dispatch(
      actions.getCriticalAndHighVulnerabilities({
        q: {
          environmentId,
          accountId: accountIds,
          startDate,
          endDate,
          chartType: VulnerabilitiesCharts.CriticalAndHighVulnerabilities,
        },
      }),
    );
  }, [
    dispatch,
    environmentId,
    accountId,
    aiParamExtraction.data,
    endDate,
    startDate,
  ]);

  return (
    <ActiveResourceProvider>
      <FilterComponent showFn={() => info.org_id !== 'master'}>
        <Routes>
          <Route element={<FilterRouter path="/dashboard/overview" />}>
            <Route path="/dashboard/overview" element={<DashboardOverview />} />
          </Route>

          <Route element={<FilterRouter path="/dashboard/data" />}>
            <Route path="data" element={<DataSecurityOverview />} />
          </Route>

          <Route element={<FilterRouter path="/dashboard/identity" />}>
            <Route path="identity" element={<IdentityOverview />} />
          </Route>

          <Route element={<FilterRouter path="/dashboard/infrastructure" />}>
            <Route path="infrastructure" element={<CloudPostureOverview />} />
          </Route>

          <Route element={<FilterRouter path="/dashboard/vulnerability" />}>
            <Route path="vulnerability" element={<VulnerabilityOverview />} />
          </Route>

          <Route element={<FilterRouter path="/dashboard" />}>
            <Route index element={<DashboardOverview />} />
          </Route>
          <Route element={<FilterRouter path="/" />}>
            <Route index element={<DashboardOverview />} />
          </Route>
        </Routes>
      </FilterComponent>
    </ActiveResourceProvider>
  );
}
