import React, { useEffect, useState } from 'react';

import { InternalContext } from '@ariksa/inventory-core/api';
import { Center } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { usePageContentContext } from 'components/Layout';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectApp } from 'containers/App/selectors';
import { InfrastructurePosture } from 'containers/Dashboard/CloudPostureOverview/Components';
import { NoAccountsOnboarded } from 'containers/Dashboard/Overview/Components/NoAccountsOnboarded';
import { AlertCategoryClass } from 'containers/Findings/FindingsOverview/types';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useInjector } from 'utils/inject';

import { cloudPostureOverviewSaga } from './saga';
import { reducer, sliceKey, actions } from './slice';

export const CloudPostureOverview: React.FC = () => {
  useInjector(sliceKey, reducer, cloudPostureOverviewSaga);
  const dispatch = useDispatch();
  const { cloudAccounts, environments, user } = useSelector(selectApp);
  const { environmentId, accountId } = useAccessBoundary();
  const { aiParamExtraction } = useSelector(selectSharedState);
  const { contentHeight } = usePageContentContext();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    setEndDate(
      aiParamExtraction.data?.end_date
        ? dayjs(aiParamExtraction.data?.end_date).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
    );

    setStartDate(
      aiParamExtraction.data?.start_date
        ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
        : dayjs().subtract(29, 'day').format('YYYY-MM-DD'),
    );
  }, [aiParamExtraction.data]);

  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;
    const accountIds = !!accountId ? [accountId] : undefined;

    /*-----------------------------infrastructure-----------------------------*/
    dispatch(
      actions.getInfrastructurePostureFindingsCategorySummary({
        q: {
          environmentId: environmentId as string,
          accountId,
          categoryClass: AlertCategoryClass.CloudPosture,
        },
      }),
    );
    dispatch(
      actions.getCloudPostureOpenAlerts({
        q: {
          environmentId: environmentId as string,
          accountId,
          categoryClass: AlertCategoryClass.CloudPosture,
        },
      }),
    );
    dispatch(
      actions.getInfrastructureThatLacksEncryption({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
        },
      }),
    );
    dispatch(
      actions.getPubliclyAccessibleSources({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
          //limit: 4,
        },
      }),
    );
    dispatch(
      actions.getHighlyExploitableMachines({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
          context: InternalContext.HighPrivileges,
          onlyMachineIdentities: true,
        },
      }),
    );
    dispatch(
      actions.getVulnerablePubliclyAccessibleSources({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
          //limit: 4,
        },
      }),
    );
    dispatch(
      actions.getVmPortUsage({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
          //limit: 3,
        },
      }),
    );
    dispatch(
      actions.getVmWithSSHTraffic({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
          //limit: 3,
        },
      }),
    );
    dispatch(
      actions.getInsightsForCloudNetworking({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
        },
      }),
    );
  }, [
    environments,
    dispatch,
    environmentId,
    accountId,
    startDate,
    endDate,
    user.info.org_uuid,
    aiParamExtraction.data,
    cloudAccounts.data,
  ]);

  return (
    <>
      {environments.isLoading || cloudAccounts.loading ? (
        <Center h={contentHeight + 'px'}>
          <CustomSpinner size="xl" />
        </Center>
      ) : (
        <>
          {!isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <InfrastructurePosture />
          )}
          {isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <NoAccountsOnboarded />
          )}
        </>
      )}
    </>
  );
};
