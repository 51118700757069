import {
  DataFootprintAnalyzed,
  ResourcesApiGetResourcesCategoriesCountRequest,
} from '@ariksa/data-scanning/api';
import {
  CloudInsightApiGetRuntimeInsightsRequest,
  CountSummary,
  DashboardApiGetDrawerConfigRequest,
  DashboardApiGetDrawerDataRequest,
  DashboardDrawerConfig,
  DataSourcesSummary,
  EntityRow,
  InventorySummary,
  ResourceApiGetDataSourcesSummaryRequest,
  ResourceApiGetInventorySummaryRequest,
  ResourceSummaryApiGetSummaryByTypeRequest,
  RuntimeInsight,
  SearchApiIsMapAvailableRequest,
} from '@ariksa/inventory-core/api';
import {
  AggregatedAlertsSummary,
  AggregatedAlertsSummaryResponse,
  AlertHistoryResponse,
  AlertsApiGetAggregatedAlertsSummaryRequest,
  AlertsApiGetAlertHistoryRequest,
  AlertsApiGetAlertingResourcesCountRequest,
  AlertsApiGetAlertSummaryRequest,
  AlertsApiGetCountByResourceUuidsRequest,
  AlertsApiGetFindingsCategoriesSummaryRequest,
  AlertSummaryResponse,
} from '@ariksa/notification/api';
import { ChartsApiGetVulnerabilitiesDetailsRequest } from '@ariksa/reporting';
import {
  ChartsApiGetDashboardResourcesHistoryRequest,
  ChartsApiGetDashboardResourcesRequest,
  ChartsApiGetTotalAlertsRequest,
  InventoryApiGetResourceCountRequest,
  ResourceCount,
  ResourceCountResponse,
  TotalAlertsResponse,
} from '@ariksa/reporting/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Tag container
export const initialState: ContainerState = {
  alertingResources: QueryState.init(0),
  openFindings: QueryState.init({}),
  inventorySummary: QueryState.init({}),
  inventoryResourcesSummary: QueryState.init({}),
  analyzedDataSourcesCount: QueryState.init({}),
  cloudSummary: QueryState.init({} as CountSummary),
  resourcesCount: QueryState.init({} as ResourceCount),
  entitiesHistory: QueryState.init({}),
  alertsHistory: QueryState.init({} as TotalAlertsResponse),
  dataCount: QueryState.init({} as ResourceCountResponse),
  findingsCategorySummary: QueryState.init([]),
  openAlerts: QueryState.init({} as AlertSummaryResponse),
  identityCount: QueryState.init({} as ResourceCountResponse),
  computeCount: QueryState.init({} as ResourceCountResponse),
  networkCount: QueryState.init({} as ResourceCountResponse),
  credentialCount: QueryState.init({} as ResourceCountResponse),
  domainsCount: QueryState.init({} as ResourceCountResponse),

  openFindingHistory: QueryState.init({} as AlertHistoryResponse),
  identityAuthorizationOpenFindingsHistory: QueryState.init(
    {} as AlertHistoryResponse,
  ),
  dataSecurityOpenFindingsHistory: QueryState.init({} as AlertHistoryResponse),
  cloudPostureOpenFindingsHistory: QueryState.init({} as AlertHistoryResponse),

  infrastructurePostureSummary: QueryState.init({}),

  summaryOfAllDataSources: QueryState.init({} as DataSourcesSummary),

  vulnerableResourcesSummary: QueryState.init({}),
  criticalAndHighVulnerabilities: QueryState.init({}),

  drawerConfig: QueryState.init({} as DashboardDrawerConfig),
  allEntities: PagedQueryState.init([]),
  alertCount: QueryState.init({}),
  securityGraphAvailability: QueryState.init({}),
  runtimeInsights: QueryState.init([]),
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    getCloudSummary(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.cloudSummary = QueryState.next(state.cloudSummary, action);
    },

    getInventorySummary(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.inventorySummary = QueryState.next(state.inventorySummary, action);
    },
    getInventoryResourcesSummary(
      state,
      action: QueryAction<
        InventorySummary,
        ResourceApiGetInventorySummaryRequest
      >,
    ) {
      state.inventoryResourcesSummary = QueryState.next(
        state.inventoryResourcesSummary,
        action,
      );
    },

    getInfrastructurePostureSummary(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.infrastructurePostureSummary = QueryState.next(
        state.infrastructurePostureSummary,
        action,
      );
    },

    getResourcesCount(
      state,
      action: QueryAction<ResourceCount, ChartsApiGetDashboardResourcesRequest>,
    ) {
      state.resourcesCount = QueryState.next(state.resourcesCount, action);
    },

    getIdentityCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.identityCount = QueryState.next(state.identityCount, action);
    },
    getComputeCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.computeCount = QueryState.next(state.computeCount, action);
    },

    getNetworkCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.networkCount = QueryState.next(state.networkCount, action);
    },

    getCredentialCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.credentialCount = QueryState.next(state.credentialCount, action);
    },

    getDomainsCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.domainsCount = QueryState.next(state.domainsCount, action);
    },

    getAlertingResources(
      state,
      action: QueryAction<number, AlertsApiGetAlertingResourcesCountRequest>,
    ) {
      state.alertingResources = QueryState.next(
        state.alertingResources,
        action,
      );
    },

    getSummaryOfAllDataSources(
      state,
      action: QueryAction<
        DataSourcesSummary,
        ResourceApiGetDataSourcesSummaryRequest
      >,
    ) {
      state.summaryOfAllDataSources = QueryState.next(
        state.summaryOfAllDataSources,
        action,
      );
    },

    getOpenAlerts(
      state,
      action: QueryAction<
        AlertSummaryResponse,
        AlertsApiGetAlertSummaryRequest
      >,
    ) {
      state.openAlerts = QueryState.next(state.openAlerts, action);
    },

    getOpenFindings(
      state,
      action: QueryAction<
        AggregatedAlertsSummaryResponse,
        AlertsApiGetAggregatedAlertsSummaryRequest
      >,
    ) {
      state.openFindings = QueryState.next(state.openFindings, action, {
        mapData: r => r.summary,
      });
    },

    getIdentityAuthorizationOpenFindingHistory(
      state,
      action: QueryAction<
        AlertHistoryResponse,
        AlertsApiGetAlertHistoryRequest
      >,
    ) {
      state.identityAuthorizationOpenFindingsHistory = QueryState.next(
        state.identityAuthorizationOpenFindingsHistory,
        action,
      );
    },
    getDataSecurityOpenFindingHistory(
      state,
      action: QueryAction<
        AlertHistoryResponse,
        AlertsApiGetAlertHistoryRequest
      >,
    ) {
      state.dataSecurityOpenFindingsHistory = QueryState.next(
        state.dataSecurityOpenFindingsHistory,
        action,
      );
    },
    getCloudPostureOpenFindingHistory(
      state,
      action: QueryAction<
        AlertHistoryResponse,
        AlertsApiGetAlertHistoryRequest
      >,
    ) {
      state.cloudPostureOpenFindingsHistory = QueryState.next(
        state.cloudPostureOpenFindingsHistory,
        action,
      );
    },
    /*--------------------------State of your cloud------------------------*/
    getEntitiesHistory(
      state,
      action: QueryAction<
        Record<string, number>,
        ChartsApiGetDashboardResourcesHistoryRequest
      >,
    ) {
      state.entitiesHistory = QueryState.next(state.entitiesHistory, action);
    },

    getDataCount(
      state,
      action: QueryAction<
        ResourceCountResponse,
        InventoryApiGetResourceCountRequest
      >,
    ) {
      state.dataCount = QueryState.next(state.dataCount, action);
    },

    getAlertsHistory(
      state,
      action: QueryAction<TotalAlertsResponse, ChartsApiGetTotalAlertsRequest>,
    ) {
      state.alertsHistory = QueryState.next(state.alertsHistory, action);
    },

    getAnalyzedDataSourcesCount(
      state,
      action: QueryAction<
        DataFootprintAnalyzed,
        ResourcesApiGetResourcesCategoriesCountRequest
      >,
    ) {
      state.analyzedDataSourcesCount = QueryState.next(
        state.analyzedDataSourcesCount,
        action,
      );
    },

    getOpenFindingHistory(
      state,
      action: QueryAction<
        AlertHistoryResponse,
        AlertsApiGetAlertHistoryRequest
      >,
    ) {
      state.openFindingHistory = QueryState.next(
        state.openFindingHistory,
        action,
      );
    },

    getFindingsCategorySummary(
      state,
      action: QueryAction<
        AggregatedAlertsSummary[],
        AlertsApiGetFindingsCategoriesSummaryRequest
      >,
    ) {
      state.findingsCategorySummary = QueryState.next(
        state.findingsCategorySummary,
        action,
      );
    },

    getVulnerableResourcesSummary(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.vulnerableResourcesSummary = QueryState.next(
        state.vulnerableResourcesSummary,
        action,
      );
    },
    getCriticalAndHighVulnerabilities(
      state,
      action: QueryAction<any, ChartsApiGetVulnerabilitiesDetailsRequest>,
    ) {
      state.criticalAndHighVulnerabilities = QueryState.next(
        state.criticalAndHighVulnerabilities,
        action,
      );
    },

    getDrawerConfig(
      state,
      action: QueryAction<
        DashboardDrawerConfig,
        DashboardApiGetDrawerConfigRequest
      >,
    ) {
      state.drawerConfig = QueryState.next(state.drawerConfig, action);
    },

    getAllEntities(
      state,
      action: QueryAction<
        EntityRow[],
        DashboardApiGetDrawerDataRequest & { total: number }
      >,
    ) {
      state.allEntities = PagedQueryState.next(state.allEntities, action, {
        mapData: r => r,
        mapTotalCount: r => action.payload.q.total,
      });
    },

    getAlertCount(
      state,
      action: QueryAction<
        Record<string, number>,
        AlertsApiGetCountByResourceUuidsRequest
      >,
    ) {
      state.alertCount = QueryState.next(state.alertCount, action);
    },

    updateAllEntitiesPagination(state, action: PayloadAction<PageInfo>) {
      state.allEntities.page.info = action.payload;
    },

    getSecurityGraphAvailability(
      state,
      action: QueryAction<
        Record<string, boolean>,
        SearchApiIsMapAvailableRequest
      >,
    ) {
      state.securityGraphAvailability = QueryState.next(
        state.securityGraphAvailability,
        action,
      );
    },

    getRuntimeInsights(
      state,
      action: QueryAction<
        RuntimeInsight[],
        CloudInsightApiGetRuntimeInsightsRequest
      >,
    ) {
      state.runtimeInsights = QueryState.next(state.runtimeInsights, action);
    },
  },
});

export const { actions, reducer, name: sliceKey } = dashboardSlice;
