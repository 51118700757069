import React, { useEffect, useState } from 'react';

import { SummaryType } from '@ariksa/inventory-core/api';
import {  VulnerabilitiesCharts } from '@ariksa/reporting/api';
import { Center } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { includes, isEmpty, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { usePageContentContext } from 'components/Layout';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { selectApp } from 'containers/App/selectors';
import { NoAccountsOnboarded } from 'containers/Dashboard/Overview/Components/NoAccountsOnboarded';
import { Vulnerabilities } from 'containers/Dashboard/VulnerabilityOverview/Components';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useInjector } from 'utils/inject';

import { vulnerabilityOverviewSaga } from './saga';
import { reducer, sliceKey, actions } from './slice';
import { Resources } from '@ariksa/reporting/dist/api';

export const VulnerabilityOverview: React.FC = () => {
  useInjector(sliceKey, reducer, vulnerabilityOverviewSaga);
  const dispatch = useDispatch();
  const { cloudAccounts, environments } = useSelector(selectApp);
  const { environmentId, accountId } = useAccessBoundary();
  const { aiParamExtraction, vulnerabilityScannerInfo } = useSelector(
    selectSharedState,
  );
  const { contentHeight } = usePageContentContext();
  const { environmentMapping } = useEnvironmentOptions();
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [vulnerabilityScannerCount, setVulnerabilityScannerCount] = useState(0);
  const [showVulnerabilities, setShowVulnerabilities] = useState(true);

  useEffect(() => {
    setEndDate(
      aiParamExtraction.data?.end_date
        ? dayjs(aiParamExtraction.data?.end_date).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
    );

    setStartDate(
      aiParamExtraction.data?.start_date
        ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
        : dayjs().subtract(29, 'day').format('YYYY-MM-DD'),
    );
  }, [aiParamExtraction.data]);

  /*vulnerabilities*/
  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;

    if (!!vulnerabilityScannerInfo.data) {
      if (!vulnerabilityScannerInfo.data) return;
      const accounts = environmentMapping?.[environmentId!]?.account_ids;
      const scanners = Object.keys(vulnerabilityScannerInfo.data);

      if (!!accountId) setShowVulnerabilities(includes(scanners, accountId));
      else setShowVulnerabilities(some(accounts, o => includes(scanners, o)));
    }
  }, [
    environmentId,
    accountId,
    aiParamExtraction.data,
    environmentMapping,
    endDate,
    startDate,
    vulnerabilityScannerInfo.data,
  ]);

  /*vulnerabilities*/
  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;
    const accountIds = !!accountId ? [accountId] : undefined;
    const startDate_7 = aiParamExtraction.data?.start_date
      ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
      : dayjs().subtract(7, 'day').format('YYYY-MM-DD');

    if (
      vulnerabilityScannerInfo.isSuccess &&
      !vulnerabilityScannerInfo.isLoading
    ) {
      if (!vulnerabilityScannerInfo.data) return;

      //if (showVulnerability) {
      dispatch(
        actions.getTopNewVulnerabilities({
          q: {
            startDate: startDate,
            endDate: endDate,
            size: 3,
            environmentId,
            accountId: accountIds,
          },
        }),
      );
      dispatch(
        actions.getMostWidelyDeployedLibraries({
          q: {
            size: 3,
            environmentId,
            accountId: accountIds,
          },
        }),
      );
      dispatch(
        actions.getHighestOccurringVulnerabilities({
          q: {
            size: 3,
            environmentId,
            accountId: accountIds,
          },
        }),
      );
      dispatch(
        actions.getVulnerabilitiesByResourceTypes({
          q: {
            //size: 3,
            environmentId,
            accountId: accountIds,
            exploitable: true,
          },
        }),
      );
      dispatch(
        actions.getExploitableEntitiesToPatch({
          q: {
            environmentId: environmentId as string,
            accountId: accountIds,
            startDate,
            endDate,
            chartType: VulnerabilitiesCharts.ExploitableEntitiesToPatch,
          },
        }),
      );
      dispatch(
        actions.getVulnerableEntitiesWithSensitiveDataAccess({
          q: {
            environmentId: environmentId as string,
            accountId: accountIds,
            summaryType: SummaryType.VulnerableWithPrivilegedAccessToSensitive,
          },
        }),
      );
      dispatch(
        actions.getInternetExposedEntitiesWithVulnerabilities({
          q: {
            environmentId: environmentId as string,
            accountId: accountIds,
            summaryType: SummaryType.ExposedAndSevere,
          },
        }),
      );
      dispatch(
        actions.getCriticalAndHighVulnerabilitiesSummary({
          q: {
            startDate,
            endDate,
            size: 3,
            environmentId,
            accountId: accountIds,
          },
        }),
      );
      dispatch(
        actions.getEOLSoftware({
          q: {
            size: 3,
            environmentId,
            accountId: accountIds,
          },
        }),
      );
      dispatch(
        actions.getServerlessVulnerabilitiesCount({
          q: {
            environmentId: environmentId as string,
            accountId: accountIds,
            agnosticResource: Resources.ServerlessCompute,
            endDate,
            startDate: startDate_7,
          },
        }),
      );
      dispatch(
        actions.getVulnerabilitiesCount({
          q: {
            environmentId: environmentId as string,
            accountId,
            startDate: startDate_7,
            endDate,
          },
        }),
      );
      dispatch(
        actions.getVmVulnerabilitiesCount({
          q: {
            environmentId: environmentId as string,
            accountId: accountIds,
            agnosticResource: Resources.VirtualMachine,
            endDate,
            startDate: startDate_7,
          },
        }),
      );
      dispatch(
        actions.getContainerVulnerabilitiesCount({
          q: {
            environmentId: environmentId as string,
            accountId: accountIds,
            agnosticResource: Resources.Containers,
            endDate,
            startDate: startDate_7,
          },
        }),
      );
      dispatch(
        actions.getContainerServiceVulnerabilitiesCount({
          q: {
            environmentId: environmentId as string,
            accountId: accountIds,
            agnosticResource: Resources.ContainerRegistry,
            endDate,
            startDate: startDate_7,
          },
        }),
      );
      dispatch(
        actions.getVmImageVulnerabilitiesCount({
          q: {
            environmentId: environmentId as string,
            accountId: accountIds,
            agnosticResource: Resources.Image,
            endDate,
            startDate: startDate_7,
          },
        }),
      );
      dispatch(
        actions.getVulnerabilityPrioritizationData({
          q: {
            environmentId: environmentId as string,
            accountId: accountIds,
          },
        }),
      );
      //}
    }
  }, [
    dispatch,
    environmentId,
    accountId,
    aiParamExtraction.data,
    environmentMapping,
    endDate,
    startDate,
    vulnerabilityScannerInfo.isLoading,
    vulnerabilityScannerInfo.isSuccess,
    vulnerabilityScannerInfo.data,
  ]);

  return (
    <>
      {environments.isLoading || cloudAccounts.loading ? (
        <Center h={contentHeight + 'px'}>
          <CustomSpinner size="xl" />
        </Center>
      ) : (
        <>
          {!isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <Vulnerabilities
              showDashboard={showVulnerabilities}
              vulnerabilityScannerCount={vulnerabilityScannerCount}
            />
          )}
          {isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <NoAccountsOnboarded />
          )}
        </>
      )}
    </>
  );
};
