import React, { useState } from 'react';

import { PanelsEnum } from '@ariksa/inventory-core/api';
import { Box, Center, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { isEmpty, map, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getIconColor } from 'components/DataDisplay';
import formatNumber from 'components/DataDisplay/Utils/formatNumber';
import { PrimaryButton } from 'components/DataEntry';
import { CustomerJourneyBulbIcon } from 'components/Icons';
import { DashboardWidget } from 'components/Visualization/CDashboard/Widget/DashboardWidget';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { selectCloudPostureOverview } from 'containers/Dashboard/CloudPostureOverview/selectors';
import { AllEntitiesDrawer } from 'containers/Dashboard/Drawers/AllEntitiesDrawer';
import { AllEntitiesDetailsProps } from 'containers/Dashboard/types';
import { renderResourcesWithIconAndCount } from 'containers/Dashboard/utils/utils';
import { selectSharedState } from 'containers/SharedState/selectors';

export const VulnerablePubliclyExposedEntities: React.FC = () => {
  const { vulnerablePubliclyAccessibleSources } = useSelector(
    selectCloudPostureOverview,
  );
  const navigate = useNavigate();
  const { isVulnerabilityScannerDeployed } = useSelector(selectSharedState);
  const { environmentId } = useAccessBoundary();
  const allEntitiesDisclosure = useDisclosure();
  const [details, setDetails] = useState<AllEntitiesDetailsProps>(
    {} as AllEntitiesDetailsProps,
  );

  return (
    <>
      <DashboardWidget
        isLoading={vulnerablePubliclyAccessibleSources.isLoading}
        label="Vulnerable Publicly Exposed Entities"
        content={
          isVulnerabilityScannerDeployed?.[environmentId!] ? (
            isEmpty(vulnerablePubliclyAccessibleSources.data) ? (
              <Box>No insights available</Box>
            ) : (
              renderResourcesWithIconAndCount(
                map(
                  orderBy(
                    vulnerablePubliclyAccessibleSources.data,
                    ['resource_count'],
                    ['desc'],
                  ),
                  o => ({
                    label: o.agnostic_name!,
                    iconType: o.agnostic_class!,
                    count: formatNumber(o?.resource_count),
                    iconColor: getIconColor(o.agnostic_class!),
                    borderColor: getIconColor(o.agnostic_class!),
                    onClick: () => {
                      allEntitiesDisclosure.onOpen();
                      setDetails({
                        panel: PanelsEnum.PubliclyAccessibleEntities,
                        iconType: o.agnostic_class!,
                        resourceType: o.agnostic_class,
                        resourceTypeClass: 'agnostic',
                        total: o?.resource_count ?? 0,
                      });
                    },
                  }),
                ),
              )
            )
          ) : (
            <HStack
              spacing={4}
              align="flex-start"
              fontSize="md"
              justify="center"
              pb={8}
            >
              <Box boxSize={10} color="orange">
                <Center>
                  <CustomerJourneyBulbIcon />
                </Center>
              </Box>
              <Stack spacing={4} flex={1}>
                <Stack color="primary" cursor="pointer" spacing={0}>
                  <Box>Deploy Outpost for</Box>
                  <Box>Vulnerability for this insight</Box>
                </Stack>
                <Box>
                  <PrimaryButton onClick={() => navigate('/setup/outpost/add/vulnerability')}>
                    DEPLOY
                  </PrimaryButton>
                </Box>
              </Stack>
            </HStack>
          )
        }
        styles={{ contentWrapper: { px: 0, pb: 0 } }}
      />
      <AllEntitiesDrawer
        isOpen={allEntitiesDisclosure.isOpen}
        onClose={allEntitiesDisclosure.onClose}
        {...details}
      />
    </>
  );
};
