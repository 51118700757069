import React, { useCallback, useEffect, useMemo } from 'react';

import { Box, Center, Flex, HStack, Stack, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers';
import { colorMap } from 'theme';

import { defaultSelectStyles } from 'components/DataEntry';
import { DropdownIndicator } from 'components/DataEntry/Search';
import { SearchIcon } from 'components/Icons';
import { getIcon } from 'components/Icons/Components';
import { useQuerySearchTerms } from 'containers/Visibility/SecurityGraphNext/hooks/useQuerySearchTerms';
import { selectSecurityGraphNext } from 'containers/Visibility/SecurityGraphNext/selectors';
import { actions } from 'containers/Visibility/SecurityGraphNext/slice';

const formatGroupLabel = data => (
  <Box
    borderBottom="1px solid"
    borderColor="gray.200"
    pb={1}
    fontSize={12}
    color="gray.250"
  >
    {data.label}
  </Box>
);

const MenuList = props => {
  return (
    <components.MenuList {...props}>
      <Stack spacing={1}>{props.children}</Stack>
    </components.MenuList>
  );
};

const CustomOption = props => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <Stack _hover={{ cursor: 'pointer' }}>
        <HStack>
          <HStack flex={1}>
            <Center p={1} w={6} h={6} color="primary">
              {getIcon(
                data.value?.native_resource || data.value?.agnostic_resource,
              )}
            </Center>
            <Text>{data.label ?? '-'}</Text>
          </HStack>
          <Flex
            px={3}
            bg={
              data.value?.is_custom_policy ? 'green.300' : colorMap.primary(300)
            }
            borderRadius={4}
            color="white"
            opacity={0.7}
            fontSize="xs"
            w="70px"
          >
            <Center w="full">
              {data.value?.is_custom_policy ? 'Custom' : 'Ariksa'}
            </Center>
          </Flex>
        </HStack>
        {/*<Text px={8} color={'#aaa'}>
          {data.value.query ?? '-'}
        </Text>*/}
      </Stack>
    </components.Option>
  );
};

const CustomValueContainer = (props: ValueContainerProps) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        <HStack w="full" h="full" overflow="auto">
          <Box color={'gray'} fontSize={'md'} flexShrink={0}>
            <SearchIcon />
          </Box>
          {props.children}
        </HStack>
      </components.ValueContainer>
    )
  );
};

export const SecurityGraphSearch = () => {
  const dispatch = useDispatch();
  const {
    recentSearchOptions,
    searchTermOptions,
    getStartedSearchOptions,
    currentSearchQuery,
  } = useSelector(selectSecurityGraphNext);
  const navigate = useNavigate();

  const {
    getSearchQuerySuggestion,
    isLoadingSuggestion,
  } = useQuerySearchTerms();

  const selectedSearchOption = useMemo(() => {
    if (!currentSearchQuery) {
      return null;
    }

    console.log(currentSearchQuery);

    return {
      label:
        (currentSearchQuery.description || currentSearchQuery.query) ?? '-',
      value: currentSearchQuery as any,
    };
  }, [currentSearchQuery]);

  const defaultSearchOptions = useMemo(() => {
    const options: any[] = [];
    if (!isEmpty(searchTermOptions)) {
      options.push({
        label: 'AI-Driven SUGGESTIONS',
        options: searchTermOptions,
      });
    }

    if (!isEmpty(recentSearchOptions.data)) {
      options.push({
        label: <Box color="primary">Recent Searches (last 5)</Box>,
        options: recentSearchOptions.data.map(s => ({
          label: s.description || s.name,
          value: { uuid: s.id, ...s },
        })),
      });
    }

    if (!isEmpty(getStartedSearchOptions.data)) {
      options.push({
        label: <Box color="primary">Get Started</Box>,
        options: getStartedSearchOptions.data.map(s => ({
          label: s.description || s.name,
          value: { uuid: s.id, ...s },
        })),
      });
    }

    return options;
  }, [recentSearchOptions, searchTermOptions, getStartedSearchOptions]);

  const handleOnChange = useCallback(
    selected => {
      dispatch(actions.updateQueryChanged(true));
      dispatch(actions.updateSelectedSearchQueryTerm(selected?.value ?? null));
      // reset the search query and filters when a new search is initiated
      navigate('/visibility/security-graph');
    },
    [dispatch, navigate],
  );

  useEffect(() => {
    dispatch(
      actions.getRecentSearchOptions({
        q: { recent: 5 },
      }),
    );
    dispatch(
      actions.getGetStartedSearchOptions({
        q: { getStarted: true, recent: 5 },
      }),
    );
  }, [dispatch]);

  return (
    <Stack flex={1} spacing={2}>
      <Box w="full">
        <AsyncSelect
          noOptionsMessage={() => 'Type to Search'}
          cacheOptions
          defaultOptions={defaultSearchOptions}
          value={selectedSearchOption}
          loadOptions={getSearchQuerySuggestion}
          onChange={handleOnChange}
          placeholder={'Search...'}
          formatGroupLabel={formatGroupLabel}
          // isDisabled={viewType}
          components={{
            Option: CustomOption,
            ValueContainer: CustomValueContainer as any,
            MenuList,
            DropdownIndicator: props => (
              <DropdownIndicator
                {...props}
                onReset={() => {
                  dispatch(actions.updateSelectedSearchQueryTerm(null));
                  dispatch(actions.updateRedirectInfo(null));
                  navigate('/visibility/security-graph');
                }}
              />
            ),
          }}
          isLoading={isLoadingSuggestion}
          maxMenuHeight={550}
          styles={{
            ...(defaultSelectStyles() as any),
            container: (...args) => ({
              ...defaultSelectStyles().container(...args),
              zIndex: 99,
              minHeight: 32,
              background: 'white',
            }),
            /*menu: args => ({
            ...defaultSelectStyles().menu(args),
            height: '200px',
          }),*/
          }}
          menuPortalTarget={document.querySelector('body')}
        />
      </Box>
    </Stack>
  );
};
