import { VulnerabilitiesApiGetEolDashboardDetailsRequest } from '@ariksa/inventory-core';
import {
  ContextApiGetRiskContextResRequest,
  CountSummary,
  EOLDashboard,
  InsightV2Pagination,
  ResourceSummaryApiGetSummaryByTypeRequest,
  RiskContextResponse,
  VulnerabilitiesApiGetEolEntitiesDashboardDetailsRequest,
} from '@ariksa/inventory-core/api';
import { ChartsApiGetVulnerabilitiesDetailsRequest } from '@ariksa/reporting/api';
import {
  VulnerabilitiesApiGetVulnerabilityCountRequest,
  VulnerabilityCountResponse,
} from '@ariksa/reporting/dist/api';
import {
  BySourcesDashboard,
  CriticalAndHighSeverityVulnerabilities,
  DefaultApiGetBySourcesDashboardDataRequest,
  DefaultApiGetCriticalAndHighSeveritySummaryRequest,
  DefaultApiGetHighestOccurringVulnerabilitiesRequest,
  DefaultApiGetMostWidelyDeployedLibVulnerabilitiesRequest,
  DefaultApiGetTopNewVulnerabilitiesRequest,
  DefaultApiGetVulnerabilityPriortizationDataRequest,
  HightestOccurringVulnerabilities,
  MostWidelyDeployedLibVulnerabilities,
  TopVulnerabilities,
  VulnerabilityPriortization,
} from '@ariksa/scan-analysis/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { QueryAction } from 'services/types';
import { PagedQueryState } from 'services/utils/PagedQueryState';
import { QueryState } from 'services/utils/QueryState';

import { PageInfo } from 'components/Navigation';
import { createSlice } from 'utils/@reduxjs/toolkit';

import { ContainerState } from './types';

// The initial state of the Tag container
export const initialState: ContainerState = {
  //vulnerabilities
  topNewVulnerabilities: QueryState.init([]),
  highestOccurringVulnerabilities: QueryState.init([]),
  mostWidelyDeployedLibraries: QueryState.init([]),
  vulnerableEntitiesWithSensitiveDataAccess: QueryState.init({}),
  internetExposedEntities: QueryState.init({}),
  criticalAndHighVulnerabilitiesSummary: QueryState.init({}),
  eolSoftware: QueryState.init({} as EOLDashboard),
  vulnerabilitiesCount: {
    vm: QueryState.init({} as VulnerabilityCountResponse),
    vmImage: QueryState.init({} as VulnerabilityCountResponse),
    containerService: QueryState.init({} as VulnerabilityCountResponse),
    container: QueryState.init({} as VulnerabilityCountResponse),
    serverless: QueryState.init({} as VulnerabilityCountResponse),
    totalVulnerabilities: QueryState.init({} as VulnerabilityCountResponse),
  },
  allEolSoftware: PagedQueryState.init([]),
  eolSoftwareEntities: PagedQueryState.init([]),
  riskContext: PagedQueryState.init({}),
  vulnerabilityPrioritization: QueryState.init(
    {} as VulnerabilityPriortization,
  ),
  vulnerabilitiesByResourceTypes: QueryState.init({} as BySourcesDashboard),
  exploitableEntitiesToPatch: QueryState.init({}),
};

const vulnerabilityOverviewSlice = createSlice({
  name: 'vulnerabilityOverview',
  initialState,
  reducers: {
    /*--------------------------Vulnerability overview------------------------*/
    getTopNewVulnerabilities(
      state,
      action: QueryAction<
        TopVulnerabilities[],
        DefaultApiGetTopNewVulnerabilitiesRequest
      >,
    ) {
      state.topNewVulnerabilities = QueryState.next(
        state.topNewVulnerabilities,
        action,
      );
    },

    getHighestOccurringVulnerabilities(
      state,
      action: QueryAction<
        HightestOccurringVulnerabilities[],
        DefaultApiGetHighestOccurringVulnerabilitiesRequest
      >,
    ) {
      state.highestOccurringVulnerabilities = QueryState.next(
        state.highestOccurringVulnerabilities,
        action,
      );
    },

    getMostWidelyDeployedLibraries(
      state,
      action: QueryAction<
        MostWidelyDeployedLibVulnerabilities[],
        DefaultApiGetMostWidelyDeployedLibVulnerabilitiesRequest
      >,
    ) {
      state.mostWidelyDeployedLibraries = QueryState.next(
        state.mostWidelyDeployedLibraries,
        action,
      );
    },

    getVulnerableEntitiesWithSensitiveDataAccess(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.vulnerableEntitiesWithSensitiveDataAccess = QueryState.next(
        state.vulnerableEntitiesWithSensitiveDataAccess,
        action,
      );
    },

    getInternetExposedEntitiesWithVulnerabilities(
      state,
      action: QueryAction<
        CountSummary,
        ResourceSummaryApiGetSummaryByTypeRequest
      >,
    ) {
      state.internetExposedEntities = QueryState.next(
        state.internetExposedEntities,
        action,
      );
    },

    getCriticalAndHighVulnerabilitiesSummary(
      state,
      action: QueryAction<
        CriticalAndHighSeverityVulnerabilities,
        DefaultApiGetCriticalAndHighSeveritySummaryRequest
      >,
    ) {
      state.criticalAndHighVulnerabilitiesSummary = QueryState.next(
        state.criticalAndHighVulnerabilitiesSummary,
        action,
      );
    },

    getEOLSoftware(
      state,
      action: QueryAction<
        EOLDashboard,
        VulnerabilitiesApiGetEolDashboardDetailsRequest
      >,
    ) {
      state.eolSoftware = QueryState.next(state.eolSoftware, action);
    },
    getAllEOLSoftware(
      state,
      action: QueryAction<
        EOLDashboard,
        VulnerabilitiesApiGetEolDashboardDetailsRequest
      >,
    ) {
      state.allEolSoftware = PagedQueryState.next(
        state.allEolSoftware,
        action,
        {
          mapData: r => r.items,
          mapTotalCount: r => r?.total_count,
        },
      );
    },
    updateEOLSoftwarePagination(state, action: PayloadAction<PageInfo>) {
      state.allEolSoftware.page.info = action.payload;
    },

    getEOLSoftwareEntities(
      state,
      action: QueryAction<
        InsightV2Pagination,
        VulnerabilitiesApiGetEolEntitiesDashboardDetailsRequest
      >,
    ) {
      state.eolSoftwareEntities = PagedQueryState.next(
        state.eolSoftwareEntities,
        action,
        {
          mapData: r => r.items,
          mapTotalCount: r => r?.total || 0,
        },
      );
    },
    updateEOLSoftwareEntitiesPagination(
      state,
      action: PayloadAction<PageInfo>,
    ) {
      state.eolSoftwareEntities.page.info = action.payload;
    },

    /*get risk context*/
    getRiskContext(
      state,
      action: QueryAction<
        Record<string, RiskContextResponse[]>,
        ContextApiGetRiskContextResRequest
      >,
    ) {
      state.riskContext = QueryState.next(state.riskContext, action);
    },

    getVulnerabilitiesCount(
      state,
      action: QueryAction<
        VulnerabilityCountResponse,
        VulnerabilitiesApiGetVulnerabilityCountRequest
      >,
    ) {
      state.vulnerabilitiesCount.totalVulnerabilities = QueryState.next(
        state.vulnerabilitiesCount.totalVulnerabilities,
        action,
      );
    },
    getVmVulnerabilitiesCount(
      state,
      action: QueryAction<
        VulnerabilityCountResponse,
        VulnerabilitiesApiGetVulnerabilityCountRequest
      >,
    ) {
      state.vulnerabilitiesCount.vm = QueryState.next(
        state.vulnerabilitiesCount.vm,
        action,
      );
    },
    getContainerVulnerabilitiesCount(
      state,
      action: QueryAction<
        VulnerabilityCountResponse,
        VulnerabilitiesApiGetVulnerabilityCountRequest
      >,
    ) {
      state.vulnerabilitiesCount.container = QueryState.next(
        state.vulnerabilitiesCount.container,
        action,
      );
    },
    getContainerServiceVulnerabilitiesCount(
      state,
      action: QueryAction<
        VulnerabilityCountResponse,
        VulnerabilitiesApiGetVulnerabilityCountRequest
      >,
    ) {
      state.vulnerabilitiesCount.containerService = QueryState.next(
        state.vulnerabilitiesCount.containerService,
        action,
      );
    },
    getVmImageVulnerabilitiesCount(
      state,
      action: QueryAction<
        VulnerabilityCountResponse,
        VulnerabilitiesApiGetVulnerabilityCountRequest
      >,
    ) {
      state.vulnerabilitiesCount.vmImage = QueryState.next(
        state.vulnerabilitiesCount.vmImage,
        action,
      );
    },
    getServerlessVulnerabilitiesCount(
      state,
      action: QueryAction<
        VulnerabilityCountResponse,
        VulnerabilitiesApiGetVulnerabilityCountRequest
      >,
    ) {
      state.vulnerabilitiesCount.serverless = QueryState.next(
        state.vulnerabilitiesCount.serverless,
        action,
      );
    },

    getVulnerabilityPrioritizationData(
      state,
      action: QueryAction<
        VulnerabilityPriortization,
        DefaultApiGetVulnerabilityPriortizationDataRequest
      >,
    ) {
      state.vulnerabilityPrioritization = QueryState.next(
        state.vulnerabilityPrioritization,
        action,
      );
    },

    getVulnerabilitiesByResourceTypes(
      state,
      action: QueryAction<
        BySourcesDashboard,
        DefaultApiGetBySourcesDashboardDataRequest
      >,
    ) {
      state.vulnerabilitiesByResourceTypes = QueryState.next(
        state.vulnerabilitiesByResourceTypes,
        action,
      );
    },

    getExploitableEntitiesToPatch(
      state,
      action: QueryAction<any, ChartsApiGetVulnerabilitiesDetailsRequest>,
    ) {
      state.exploitableEntitiesToPatch = QueryState.next(
        state.exploitableEntitiesToPatch,
        action,
      );
    },
  },
});

export const { actions, reducer, name: sliceKey } = vulnerabilityOverviewSlice;
