import React, { useEffect, useState } from 'react';

import { SummaryType } from '@ariksa/inventory-core/api';
import { Center, Stack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { includes, isEmpty, some } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { usePageContentContext } from 'components/Layout';
import { useAccessBoundary } from 'containers/App/hooks/useAccessBoundary';
import { useEnvironmentOptions } from 'containers/App/hooks/useEnvironmentOptions';
import { selectApp } from 'containers/App/selectors';
import { StateOfYourCloud } from 'containers/Dashboard/Overview/Components';
import { NoAccountsOnboarded } from 'containers/Dashboard/Overview/Components/NoAccountsOnboarded';
import { selectDashboardOverview } from 'containers/Dashboard/Overview/selectors';
import { actions as dashboardActions } from 'containers/Dashboard/slice';
import { selectSharedState } from 'containers/SharedState/selectors';
import { useInjector } from 'utils/inject';

import { dashboardOverviewSaga } from './saga';
import { reducer, sliceKey, actions } from './slice';

export const DashboardOverview: React.FC = () => {
  useInjector(sliceKey, reducer, dashboardOverviewSaga);
  const dispatch = useDispatch();
  const { cloudAccounts, environments, user } = useSelector(selectApp);
  const { accountStatus, vpcStatus } = useSelector(selectDashboardOverview);
  const { environmentId, accountId } = useAccessBoundary();
  const {
    aiParamExtraction,
    vulnerabilityScannerInfo,
    dataScannerInfo,
  } = useSelector(selectSharedState);
  const { contentHeight } = usePageContentContext();
  const { environmentMapping } = useEnvironmentOptions();
  const [showDataSecurity, setShowDataSecurity] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    setEndDate(
      aiParamExtraction.data?.end_date
        ? dayjs(aiParamExtraction.data?.end_date).format('YYYY-MM-DD')
        : dayjs().format('YYYY-MM-DD'),
    );

    setStartDate(
      aiParamExtraction.data?.start_date
        ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
        : dayjs().subtract(29, 'day').format('YYYY-MM-DD'),
    );
  }, [aiParamExtraction.data]);

  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;
    const accountIds = !!accountId ? [accountId] : undefined;

    const payloadWithDatesAccountIds = {
      environmentId: environmentId as string,
      accountIds,
      startDate,
      endDate,
    };
    const startDate_7 = aiParamExtraction.data?.start_date
      ? dayjs(aiParamExtraction.data?.start_date).format('YYYY-MM-DD')
      : dayjs().subtract(7, 'day').format('YYYY-MM-DD');

    dispatch(
      actions.getResourcesCount({
        q: {
          environmentId: environmentId as string,
          accountId: accountIds,
        },
      }),
    );
    dispatch(
      actions.getCloudSummary({
        q: {
          environmentId: environmentId as string,
          accountId: !!accountId ? [accountId] : [],
          summaryType: SummaryType.Org,
        },
      }),
    );
    dispatch(
      dashboardActions.getRuntimeInsights({
        q: {
          environmentId,
          accountId: !!accountId ? [accountId] : [],
          startDate: aiParamExtraction.data?.start_date
            ? dayjs(aiParamExtraction.data?.start_date)
                .utc()
                .format('YYYY-MM-DD')
            : dayjs().subtract(29, 'day').utc().format('YYYY-MM-DD'),
          endDate: aiParamExtraction.data?.end_date
            ? dayjs(aiParamExtraction.data?.end_date).utc().format('YYYY-MM-DD')
            : dayjs().utc().format('YYYY-MM-DD'),
        },
      }),
    );

    /*--------------------------State of your cloud-------------------------*/
    dispatch(
      actions.getEntitiesHistory({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
        },
      }),
    );
    dispatch(
      actions.getAlertsHistory({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate,
          endDate,
        },
      }),
    );
    dispatch(
      actions.getDataCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Data',
        },
      }),
    );
    dispatch(
      actions.getPolicyManagementSummary({
        q: {
          environmentId: environmentId as string,
          accountId,
        },
      }),
    );
    dispatch(
      actions.getAlertWorkflows({
        q: {
          environmentId,
        },
      }),
    );
    dispatch(
      actions.getInfrastructureCount({
        q: {
          environmentId: environmentId as string,
          accountId,
          startDate: startDate_7,
          endDate,
          category: 'Infrastructure',
        },
      }),
    );
    dispatch(
      actions.getComplianceSummary({
        q: {
          environmentId: environmentId as string,
          accountId,
        },
      }),
    );
    dispatch(
      actions.getGovernanceScore({
        q: {
          limit: 3,
          ascending: true,
          startDate: startDate_7,
          endDate,
        },
      }),
    );
    /*Response and workflows - Last 30 days*/
    dispatch(
      actions.getTicketSummary({
        q: payloadWithDatesAccountIds,
      }),
    );
    dispatch(
      actions.getTicketHistory({
        q: payloadWithDatesAccountIds,
      }),
    );
  }, [
    environments,
    dispatch,
    environmentId,
    accountId,
    startDate,
    endDate,
    user.info.org_uuid,
    aiParamExtraction.data,
    cloudAccounts.data,
  ]);

  /*vulnerabilities*/
  useEffect(() => {
    if ((!environmentId && !accountId) || !startDate || !endDate) return;

    if (!!dataScannerInfo.data) {
      if (!dataScannerInfo.data) return;
      const scanners = Object.keys(dataScannerInfo.data!);
      let showDataSecurity = false;
      const accounts = environmentMapping?.[environmentId!]?.account_ids;
      if (!!accountId) {
        showDataSecurity = includes(scanners, accountId);
      } else {
        showDataSecurity = some(accounts, o => includes(scanners, o));
      }
      setShowDataSecurity(showDataSecurity);
    }
  }, [
    environmentId,
    accountId,
    aiParamExtraction.data,
    environmentMapping,
    endDate,
    startDate,
    vulnerabilityScannerInfo.data,
    dataScannerInfo.data,
  ]);

  useEffect(() => {
    dispatch(
      actions.getAccountStatus({
        q: {
          orgId: user.info.org_uuid,
        },
      }),
    );
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(
      actions.getVpcStatus({
        q: {
          environmentId: environmentId as string,
          summaryType: 'org',
        },
      }),
    );
  }, [dispatch, environmentId]);

  return (
    <>
      {environments.isLoading || cloudAccounts.loading ? (
        <Center h={contentHeight + 'px'}>
          <CustomSpinner size="xl" />
        </Center>
      ) : (
        <>
          {!isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <Stack pb={6} spacing={4}>
              <StateOfYourCloud
                isDataClassificationEnabled={showDataSecurity}
                isActivityLogEnabled={
                  accountStatus.data?.enabled_account_ids?.length
                }
              />
            </Stack>
          )}
          {isEmpty(cloudAccounts.data) && cloudAccounts.loaded && (
            <NoAccountsOnboarded />
          )}
        </>
      )}
    </>
  );
};
