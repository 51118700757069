import React from 'react';

import { useSelector } from 'react-redux';
import { colorOpacity, customTheme } from 'theme';

import { CustomSpinner } from 'components/DataDisplay/Spinner/CustomSpinner';
import { VulnerabilityIcon } from 'components/Icons';
import { CDashboard } from 'components/Visualization';
import { IDashboardLayout } from 'components/Visualization/CDashboard/types';
import { DashboardOverviewWrapperCard } from 'containers/Dashboard/utils/DashboardOverviewWrapperCard';
import { NoVulnerabilityScannerDeployed } from 'containers/Dashboard/utils/NoVulnerabilityScannerDeployed';
import { ContainerImages } from 'containers/Dashboard/VulnerabilityOverview/Components/ContainerImages';
import { Containers } from 'containers/Dashboard/VulnerabilityOverview/Components/Containers';
import { EndOfLifeSoftware } from 'containers/Dashboard/VulnerabilityOverview/Components/EndOfLifeSoftware';
import { ExploitableEntitiesToPatch } from 'containers/Dashboard/VulnerabilityOverview/Components/ExploitableEntitiesToPatch';
import { ExposedEntitiesWithVulnerabilities } from 'containers/Dashboard/VulnerabilityOverview/Components/ExposedEntitiesWithVulnerabilities';
import { MostDeployedExploitablePackages } from 'containers/Dashboard/VulnerabilityOverview/Components/MostDeployedExploitablePackages';
import { Serverless } from 'containers/Dashboard/VulnerabilityOverview/Components/Serverless';
import { TopNewVulnerabilities } from 'containers/Dashboard/VulnerabilityOverview/Components/TopNewVulnerabilities';
import { VirtualMachines } from 'containers/Dashboard/VulnerabilityOverview/Components/VirtualMachines';
import { VMImages } from 'containers/Dashboard/VulnerabilityOverview/Components/VMImages';
import { VulnerabilitiesByResourceTypes } from 'containers/Dashboard/VulnerabilityOverview/Components/VulnerabilitiesByResourceTypes';
import { VulnerabilitiesPrioritization } from 'containers/Dashboard/VulnerabilityOverview/Components/VulnerabilitiesPrioritization';
import { Vulnerability } from 'containers/Dashboard/VulnerabilityOverview/Components/Vulnerability';
import { VulnerableHostsWithAccessToSensitiveData } from 'containers/Dashboard/VulnerabilityOverview/Components/VulnerableHostsWithAccessToSensitiveData';
import { selectSharedState } from 'containers/SharedState/selectors';

interface Props {
  showDashboard: boolean;
  vulnerabilityScannerCount: number;
}

export const Vulnerabilities: React.FC<Props> = props => {
  const { showDashboard, vulnerabilityScannerCount } = props;
  const { vulnerabilityScannerInfo } = useSelector(selectSharedState);

  const overviewDashboardWidgets = {
    summary: {
      flex: true,
      components: {
        content: (
          <Vulnerability
            vulnerabilityScannerCount={vulnerabilityScannerCount}
            isOutpostEnabled={showDashboard}
          />
        ),
      },
    },
    virtualMachines: {
      flex: true,
      components: {
        content: <VirtualMachines />,
      },
    },
    containers: {
      flex: true,
      components: {
        content: <Containers />,
      },
    },
    serverless: {
      flex: true,
      components: {
        content: <Serverless />,
      },
    },
    vmImages: {
      flex: true,
      components: {
        content: <VMImages />,
      },
    },
    ContainerService: {
      flex: true,
      components: {
        content: <ContainerImages />,
      },
    },
    vulnerabilitiesPrioritization: {
      flex: true,
      components: {
        content: <VulnerabilitiesPrioritization />,
      },
    },
    exploitableEntitiesToPatch: {
      flex: true,
      components: {
        content: <ExploitableEntitiesToPatch />,
      },
    },
    vulnerabilitiesByResourceTypes: {
      flex: true,
      components: {
        content: <VulnerabilitiesByResourceTypes />,
      },
    },
    topNewVulnerabilities: {
      flex: true,
      components: {
        content: <TopNewVulnerabilities />,
      },
    },
    exposedEntitiesWithVulnerabilities: {
      flex: true,
      components: {
        content: <ExposedEntitiesWithVulnerabilities />,
      },
    },
    vulnerableHostsWithAccessToSensitiveData: {
      flex: true,
      components: {
        content: (
          <VulnerableHostsWithAccessToSensitiveData
            scannerDeployed={showDashboard}
          />
        ),
      },
    },
    mostDeployedExploitablePackages: {
      flex: true,
      components: {
        content: <MostDeployedExploitablePackages />,
      },
    },
    endOfLifeSoftware: {
      flex: true,
      components: {
        content: <EndOfLifeSoftware />,
      },
    },
  };

  const vulnerabilityDashboardConfig: IDashboardLayout = {
    id: 'overview_dashboard',
    title: 'Overview Dashboard',
    widgets: [
      {
        type: 'card',
        i: 'summary',
        x: 0,
        y: 0,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'virtualMachines',
        x: 0,
        y: 3.8,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'containers',
        x: 0,
        y: 7.6,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'serverless',
        x: 0,
        y: 11.4,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'vmImages',
        x: 0,
        y: 15.2,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'ContainerService',
        x: 0,
        y: 19,
        w: 2.4,
        h: 3.8,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'vulnerabilitiesPrioritization',
        x: 2.4,
        y: 0,
        w: 4.8,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'exploitableEntitiesToPatch',
        x: 7.2,
        y: 0,
        w: 4.8,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'vulnerabilitiesByResourceTypes',
        x: 2.4,
        y: 7.6,
        w: 3.2,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'exposedEntitiesWithVulnerabilities',
        x: 5.6,
        y: 7.6,
        w: 3.2,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'topNewVulnerabilities',
        x: 8.8,
        y: 7.6,
        w: 3.2,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'vulnerableHostsWithAccessToSensitiveData',
        x: 2.4,
        y: 15.2,
        w: 3.2,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'mostDeployedExploitablePackages',
        x: 5.6,
        y: 15.2,
        w: 3.2,
        h: 7.6,
        isResizable: false,
        static: true,
      },
      {
        type: 'card',
        i: 'endOfLifeSoftware',
        x: 8.8,
        y: 15.2,
        w: 3.2,
        h: 7.6,
        isResizable: false,
        static: true,
      },
    ],
    available: overviewDashboardWidgets,
  };

  return (
    <DashboardOverviewWrapperCard
      label="Software Vulnerabilities"
      titleBgColor={colorOpacity(customTheme.colors.critical, 0.2)}
      content={
        vulnerabilityScannerInfo.isLoading ? (
          <CustomSpinner size="xl" />
        ) : showDashboard ? (
          <CDashboard dashboardWidgets={vulnerabilityDashboardConfig} />
        ) : (
          <NoVulnerabilityScannerDeployed />
        )
      }
      icon={<VulnerabilityIcon />}
      redirectLink="/visibility/vulnerability"
    />
  );
};
